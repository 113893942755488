const state = {
  id: null,
  display_name: '',
  office: '',
  owner: '',
  parts: [],
  collaborators: [],
}

export const requiredFields = [{ key: 'display_name' }, { key: 'owner' }]

export default state
